import * as React from "react";
import { useContext } from "timvir/context";
import { defaultSearch } from "timvir/search";
import { useImmer } from "use-immer";
import Action from "./Action";
interface Props {
  open?: boolean;
  onClose?: () => void;
  onDispose?: () => void;
}
function Dialog(props: Props) {
  const {
    toc
  } = useContext();
  const {
    open,
    onClose,
    onDispose,
    ...rest
  } = props;
  const [state, mutate] = useImmer({
    style: {
      opacity: 0,
      transform: "scale(0.98)"
    } as React.CSSProperties,
    query: "",
    commands: [] as Array<{
      node: {
        path: string;
        label: string;
      };
    }>
  });
  React.useEffect(() => {
    mutate(draft => {
      if (open) {
        draft.style = {
          opacity: 1,
          transform: "none"
        };
      } else {
        draft.style = {
          opacity: 0,
          transform: "scale(0.95)"
        };
        setTimeout(() => {
          onDispose?.();
        }, 200);
      }
    });
  }, [mutate, open]);
  React.useEffect(() => {
    (async () => {
      const {
        edges
      } = await defaultSearch(toc).q(state.query);
      mutate(draft => {
        draft.commands = edges;
      });
    })();
  }, [mutate, state.query]);
  return <div className={classes.root} style={state.style} {...rest}>
      <div className={classes.context}>Context</div>

      <div className={classes.prompt}>
        <input autoFocus placeholder="Type a command or search…" value={state.query} onChange={ev => {
        const query = ev.currentTarget.value;
        mutate(draft => {
          draft.query = query;
        });
      }} />
      </div>

      <div>
        <div className={classes.subheader}>Pages</div>

        <div className={classes.commands}>
          {state.commands.map(({
          node
        }, index) => <Action key={index} label={node.label} onClick={() => {
          onClose?.();
          window.location.href = node.path;
        }} />)}
        </div>
      </div>
    </div>;
}
export default Dialog;
const classes = {
  root: "r1fo6voy",
  context: "c185xiij",
  prompt: "p1ky3ya3",
  subheader: "snq1o7g",
  commands: "cchvluj"
};

require("./Dialog.linaria.module.css!=!../../../../../../node_modules/.pnpm/@linaria+webpack5-loader@5.0.4_webpack@5.93.0/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Dialog.tsx");